import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  allRoles() {
    return api.get(`role`);
  },
  allPermissions() {
    return api.get(`role/permissions`);
  },
  createRole(data) {
    return api.post(`role`, data);
  },
  updateRole(data) {
    return api.put(`role/${data.id}`, { name: data.name });
  },
  deleteRole(id) {
    return api.delete(`role/${id}`);
  },
  assignPermissionToRole(data) {
    return api.put(`role/permissions/${data.id}`, {
      permissions: data.permissions,
    });
  },
  assignRoleToUser(data) {
    return api.put(`role/user-role`, data);
  },
  assignPermissionsToUser(data) {
    return api.put(`role/user-permission`, data);
  },
};
