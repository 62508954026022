import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  newCustomers(data) {
    // const p = paramsFactory(params);
    return api.post(`client/task/new-customers`, data);
  },
  maintenanceTasks(data) {
    // const p = paramsFactory(params);
    return api.post(`client/task/maintenance`, data);
  },
  create(data) {
    return api.post(`client/task`, data);
  },
  showById(id) {
    return api.get(`client/task/${id}`);
  },
  status() {
    return api.get(`client/task/status`);
  },
  reply(data) {
    return api.post(`client/task-reply`, data);
  },
  assignResponsible(data) {
    return api.put(`client/task/assign-responsible/${data.task_id}`, data);
  },
  changeStatus(data) {
    return api.put(`client/task/change-status/${data.id}`, data);
  },
  update(data) {
    return api.put(`client/task/${data.id}`, data);
  },
  delete(id) {
    return api.delete(`client/task/${id}`);
  },
  deleteReply(id) {
    return api.delete(`client/task-reply/${id}`);
  },
  solutions() {
    return api.get(`client/task/solutions`);
  },
  types() {
    return api.get(`client/task/type`);
  },
  addSolutions(data) {
    return api.put(`client/task/solutions/${data.task_id}`, data);
  },
  assignMultipleResponsible(data) {
    return api.put(`client/task/assign-multi-responsible`, data);
  },
  subscriptionRequestTasks(data) {
    return api.post(`client/task/subscription-request`, data);
  },
  fastSubscribe(id) {
    return api.put(`client/task/collect-money/${id}`);
  },
  changeSubscriptionRequests(id, data) {
    return api.put(`client/task/change-subscription/${id}`, data);
  },
  getTaskNotification() {
    return api.get(`client/task/statistics`);
  },
};
