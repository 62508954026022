import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import dayjs from "dayjs";
const clientApi = factory.get("clients");

const initialState = {
  clients: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      is_active: null,
      city_id: null,
    },
    dialog: false,
  },
  client: {
    dialog: false,
    type: "add",
    loading: false,
    form: {
      id: "",
      name: "",
      user_name: "",
      password: "",
      confirm_password: "",
      mobile: "",
      email: "",
      city_id: null,
      tower_name: "",
      attachments: [],
      temp_attachments: [],
    },
    errors: {},
  },
  transactions: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      start_date: null,
      end_date: null,
      type: null,
      customer_id: null,
    },
    dialog: false,
  },
  balance: {
    dialog: false,
    loading: false,
    form: {
      amount: "",
      note: "",
    },
    errors: {},
  },
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, { payload }) => {
      state.clients.data = payload.data;
      state.clients.total = payload.total;
      state.clients.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.clients.filters = {
        ...state.clients.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.clients.filters = initialState.clients.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.client.form = {
          ...payload,
          city_id: {
            id: payload.city_id,
            name: payload.city,
          },
          attachments: [],
          temp_attachments: payload.attachments.map((item) => {
            return {
              id: item.id,
              path: item.path,
            };
          }),
        };
      }

      state.client.dialog = !state.client.dialog;
      //clear after close
      if (!state.client.dialog) {
        state.client.form = initialState.client.form;
        state.client.type = "add";
      }
    },
    deletAttachment: (state, { payload }) => {
      state.client.form.temp_attachments =
        state.client.form.temp_attachments.filter(
          (item) => item.id !== payload
        );
    },
    setFiltersDialog: (state, { payload }) => {
      state.clients.dialog = !state.clients.dialog;
    },
    resetForm: (state) => {
      state.client.form = initialState.client.form;
    },
    setErrors: (state, { payload }) => {
      state.client.errors = payload;
    },
    setTransactionsFilters: (state, { payload }) => {
      state.transactions.filters = {
        ...state.transactions.filters,
        ...payload,
      };
    },
    resetTransactionsFilters: (state) => {
      state.transactions.filters = initialState.transactions.filters;
    },
    setTransactions: (state, { payload }) => {
      state.transactions.data = payload.data;
      state.transactions.total = payload.total;
      state.transactions.loading = false;
    },
    setTransactionsFiltersDialog: (state, { payload }) => {
      state.transactions.dialog = !state.transactions.dialog;
    },
    setBalanceDialog(state, { payload }) {
      state.balance.dialog = !state.balance.dialog;
      if (!state.balance.dialog) {
        state.balance.form = initialState.balance.form;
      }
    },
  },
});

export const {
  setClients,
  setLoading,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setErrors,
  setFiltersDialog,
  deletAttachment,
  setTransactions,
  setBalanceDialog,
  setTransactionsFilters,
  resetTransactionsFilters,
  setTransactionsFiltersDialog,
} = clientsSlice.actions;

export default clientsSlice.reducer;

const toNumber = (value) => {
  return typeof value === "number" ? value : parseInt(value.replace(/,/g, ""));
};
//axios
export const transactions = () => async (dispatch, getState) => {
  try {
    const filters = getState().clients.transactions.filters;
    const filters_params = {
      ...filters,
      customer_id: filters.customer_id?.id || null,
    };
    dispatch(setLoading("transactions"));
    const res = await clientApi.getTransactions(filters_params);
    dispatch(setTransactions(res.data));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setLoading("transactions"));
    throw new Error(err);
  }
};
export const addRemoveBalance = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("balance"));
    await clientApi.addRemoveBalance({
      ...data,
      amount: toNumber(data.amount),
    });
    dispatch(
      showNotification({
        message: "تم  شحن الرصيد  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("balance"));
    dispatch(setBalanceDialog());
    dispatch(transactions());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("balance"));
    throw new Error(err);
  }
};
export const transactionsToExcel = () => async (dispatch, getState) => {
  try {
    const filters = getState().clients.transactions.filters;
    dispatch(setLoading("transactions"));
    const res = await clientApi.exportToExcel({
      ...filters,
      start_date: filters.start_date || null,
      end_date: filters.end_date || null,
      type: filters.type || null,
      client_id: filters.client_id?.id || null,
      customer_id: filters.customer_id?.id || null,
      wallet_type: filters.wallet_type || null,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "transactions.xlsx";
    a.click();
    dispatch(setLoading("transactions"));
  } catch (err) {
    dispatch(setLoading("transactions"));
    throw new Error(err);
  }
};
export const deleteAttachment = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("client"));
    await clientApi.deleteAttachment(id);
    dispatch(deletAttachment(id));
    dispatch(
      showNotification({
        message: "تم حذف الملف  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("client"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("client"));
    throw new Error(err);
  }
};

export const Clients = {
  transactions,
  addRemoveBalance,
  transactionsToExcel,
  deleteAttachment,
};
