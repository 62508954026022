import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import dayjs from "dayjs";

const subscriptionsServicesApi = factory.get("subscriptions_services");

const initialState = {
  my_subscriptions_servicess: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      mobile: "",
      customer_id: null,
      start_date: null,
      end_date: null,
      account_type_id: null,
      is_confirmed: null,
    },
    dialog: false,
  },
  subscribe_service: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      auto_renewal: false,
      message: null,
    },
    errors: {},
  },
  services: {
    data: [],
    loading: false,
  },
};

const subscriptionsServicesSlice = createSlice({
  name: "subscriptionsServices",
  initialState,
  reducers: {
    setMySubscriptions: (state, { payload }) => {
      state.my_subscriptions_servicess.data = payload.data;
      state.my_subscriptions_servicess.total = payload.total;
      state.my_subscriptions_servicess.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.my_subscriptions_servicess.filters = {
        ...state.my_subscriptions_servicess.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.my_subscriptions_servicess.filters =
        initialState.my_subscriptions_servicess.filters;
    },
    setDialog: (state, { payload }) => {
      state.subscribe_service.dialog = !state.subscribe_service.dialog;
      //clear after close
      if (!state.subscribe_service.dialog) {
        state.subscribe_service.form = initialState.subscribe_service.form;
        state.subscribe_service.type = "add";
      }
    },
    resetForm: (state) => {
      state.subscribe_service.form = initialState.subscribe_service.form;
    },
    setErrors: (state, { payload }) => {
      state.subscribe_service.errors = payload;
    },
    setServices: (state, { payload }) => {
      state.services.data = payload.data;
    },
  },
});

export const {
  setMySubscriptions,
  setLoading,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setErrors,
  setServices,
} = subscriptionsServicesSlice.actions;

export default subscriptionsServicesSlice.reducer;

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters =
      getState().subscriptionsServices.my_subscriptions_servicess.filters;
    const filters_params = {
      ...filters,
      // account_type_id: filters.account_type_id?.id || null,
      // customer_id: filters.customer_id?.id || null,
    };
    dispatch(setLoading("my_subscriptions_servicess"));
    const res = await subscriptionsServicesApi.index(filters_params);
    dispatch(setMySubscriptions(res.data));
  } catch (err) {
    dispatch(setLoading("my_subscriptions_servicess"));
    throw new Error(err);
  }
};
export const getServices = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("services"));
    const res = await subscriptionsServicesApi.getServices();
    dispatch(setServices(res.data));
    dispatch(setLoading("services"));
  } catch (err) {
    throw new Error(err);
  }
};
export const subscribe = (data, type) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("subscribe_service"));
    await subscriptionsServicesApi.subscribeToService({
      id: data.id,
      auto_renewal: data.auto_renewal,
      message: data.message,
    });

    dispatch(setLoading("subscribe_service"));
    dispatch(
      showNotification({
        message: "تم الاشتراك بنجاح",
        type: "success",
      })
    );

    if (type === "dialog") {
      dispatch(setDialog());
      dispatch(resetForm());
    }
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("subscribe_service"));
    throw new Error(err);
  }
};

export const SubscriptionsServices = {
  index,
  subscribe,
  getServices,
};
