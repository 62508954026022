import { lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
import Loadable from "./../components/Loadable";

// dashboard routing
const Home = Loadable(lazy(() => import("../views/home")));
const Customers = Loadable(lazy(() => import("../views/customers")));
const CustomersManage = Loadable(
  lazy(() => import("../views/customers/manage"))
);
const Subscriptions = Loadable(lazy(() => import("../views/subscriptions")));
const Reports = Loadable(lazy(() => import("../views/reports")));
const Task = Loadable(lazy(() => import("../views/task")));
const MaintenanceTask = Loadable(
  lazy(() => import("../views/maintenance-task"))
);
const MaintenanceTaskReplies = Loadable(
  lazy(() => import("../views/task/task-replies/index"))
);
const TaskReplies = Loadable(
  lazy(() => import("../views/task/task-replies/index"))
);
const CallCustomerHistory = Loadable(
  lazy(() => import("../views/call-customer-history"))
);
const SubscriptionService = Loadable(
  lazy(() => import("../views/subscription-service"))
);
const Transactions = Loadable(lazy(() => import("../views/transactions")));
const Profile = Loadable(lazy(() => import("../views/profile")));
const SubscriptionRequestTask = Loadable(
  lazy(() => import("../views/subscription-request-task"))
);
const NotificationsAnnouncement = Loadable(
  lazy(() => import("../views/notifications-announcement"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/subscription-service",
      children: [
        {
          path: "/subscription-service",
          element: <SubscriptionService />,
          index: true,
        },
      ],
    },
    {
      path: "/notifications-announcement",
      children: [
        {
          path: "/notifications-announcement",
          element: <NotificationsAnnouncement />,
          index: true,
        },
      ],
    },
    {
      path: "/call-customer-history",
      children: [
        {
          path: "/call-customer-history",
          element: <CallCustomerHistory />,
          index: true,
        },
      ],
    },
    {
      path: "/subscription-request-task",
      children: [
        {
          path: "/subscription-request-task",
          element: <SubscriptionRequestTask />,
          index: true,
        },
        {
          path: "/subscription-request-task/:id",
          element: <MaintenanceTaskReplies />,
        },
      ],
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/profile",
      children: [
        {
          path: "/profile",
          element: <Profile />,
          index: true,
        },
      ],
    },
    {
      path: "/transactions",
      children: [
        {
          path: "/transactions",
          element: <Transactions />,
          index: true,
        },
      ],
    },
    {
      path: "/task",
      children: [
        {
          path: "/task",
          element: <Task />,
          index: true,
        },
        {
          path: "/task/:id",
          element: <TaskReplies />,
        },
      ],
    },
    {
      path: "/maintenance-task",
      children: [
        {
          path: "/maintenance-task",
          element: <MaintenanceTask />,
          index: true,
        },
        {
          path: "/maintenance-task/:id",
          element: <MaintenanceTaskReplies />,
        },
      ],
    },
    {
      path: "/customers",
      children: [
        {
          path: "/customers",
          element: <Customers />,
          index: true,
        },
        {
          path: "/customers/add",
          element: <CustomersManage />,
        },
        {
          path: "/customers/edit/:id",
          element: <CustomersManage />,
        },
      ],
    },
    {
      path: "/subscriptions",
      children: [
        {
          path: "/subscriptions",
          element: <Subscriptions />,
          index: true,
        },
      ],
    },
    {
      path: "/reports",
      children: [
        {
          path: "/reports",
          element: <Reports />,
        },
      ],
    },
  ],
};

export default MainRoutes;
