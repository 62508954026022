import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import { BiLogOutCircle, BiUserCircle } from "react-icons/bi";
import { GoDeviceMobile } from "react-icons/go";
import {
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Avatar,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "../../../app/slices/authSlice";
import { TbSignature } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { TbFileInvoice } from "react-icons/tb";

const ProfileMenu = () => {
  const { user } = useSelector((state) => state.profile);
  // const {
  //   final_accounts: { only_user_ids_can_show },
  // } = useSelector((state) => state.statistics);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  // const onlyForThisUsers = (id) => {
  //   return only_user_ids_can_show.includes(id);
  // };

  return (
    <div>
      <List role={"button"} onClick={handleMenu}>
        <ListItemButton>
          <ListItemAvatar>
            <Avatar>
              <AccountCircle />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="h6" fontWeight={"bold"}>
                {user.info?.name}
              </Typography>
            }
            secondary={
              <Typography variant="caption" color="wheat">
                {user.info.tower_name}
              </Typography>
            }
          />
        </ListItemButton>
      </List>

      <Menu
        dir="rtl"
        sx={{ mt: "45px" }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigate("/profile");
            handleClose();
          }}
        >
          <ListItemIcon>
            <BiUserCircle />
          </ListItemIcon>
          <ListItemText primary="الصفحة الشخصية" />
        </MenuItem>

        {/* {onlyForThisUsers(user.info.id) && (
          <MenuItem
            onClick={() => {
              navigate("/final-accounts");
              handleClose();
            }}
          >
            <ListItemIcon>
              <TbFileInvoice />
            </ListItemIcon>
            <ListItemText primary="الحسابات الختامية" />
          </MenuItem>
        )} */}

        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            dispatch(Auth.logout());
          }}
        >
          <ListItemIcon>
            <BiLogOutCircle />
          </ListItemIcon>
          <ListItemText primary="تسجيل خروج" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
