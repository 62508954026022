import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const announcementApi = factory.get("announcement");

const initialState = {
  announcements: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      is_hidden: null,
    },
    dialog: false,
  },
  last_announcements: {
    data: [],
    loading: false,
    total: 0,
  },
  announcement: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      title: null,
      description: "",
      is_hidden: false,
    },
    errors: {},
  },
};

const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    setAnnouncements: (state, { payload }) => {
      state.announcements.data = payload.data;
      state.announcements.total = payload.total;
    },
    setLastAnnouncements: (state, { payload }) => {
      state.last_announcements.data = payload.data;
      state.last_announcements.total = payload.total;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.announcements.filters = {
        ...state.announcements.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.announcements.filters = initialState.announcements.filters;
    },
    setDialog: (state, { payload }) => {
      state.announcement.form.id = payload?.id || "";
      state.announcement.form.title = payload?.title || "";
      state.announcement.form.description = payload?.description || "";
      state.announcement.form.is_hidden = payload?.is_hidden || false;

      state.announcement.dialog = !state.announcement.dialog;
    },
    setFiltersDialog: (state, { payload }) => {
      state.announcements.dialog = !state.announcements.dialog;
    },
  },
});

export const {
  setAnnouncements,
  setLoading,
  setFilters,
  resetFilters,
  setDialog,
  setFiltersDialog,
  setLastAnnouncements,
} = announcementSlice.actions;

export default announcementSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("announcements"));
    const filters = getState().announcement.announcements.filters;
    const filters_params = {
      ...filters,
    };
    const res = await announcementApi.index(filters_params);
    dispatch(setAnnouncements(res.data));
    dispatch(setLoading("announcements"));
  } catch (err) {
    dispatch(setLoading("announcements"));
    throw new Error(err);
  }
};

const lastAnnouncement = () => async (dispatch) => {
  try {
    dispatch(setLoading("last_announcements"));
    const res = await announcementApi.index({
      skip: 0,
      take: 1,
    });
    dispatch(setLastAnnouncements(res.data));
    dispatch(setLoading("last_announcements"));
  } catch (err) {
    dispatch(setLoading("last_announcements"));
    throw new Error(err);
  }
};

export const Announcements = {
  index,
  lastAnnouncement,
};
