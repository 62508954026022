import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const statisticsApi = factory.get("statistics");

const initialState = {
  cards: {
    data: {
      customers: 0,
      active_customers: 0,
      inactive_customers: 0,
      balance: 0,
      commissions: 0,
      expire_soon: 0,
      expire: 0,
    },
    loading: false,
  },
  chart: {
    loading: false,
    filters: {
      start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
      end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
    },
    data: [],
  },
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setPoleData: (state, { payload }) => {
      state.pole.data = payload.data.data[0];
      state.pole.loading = false;
    },
    setPoleDialog: (state, { payload }) => {
      state.pole.cabinet_id = payload ? payload : null;
      state.pole.dialog = !state.pole.dialog;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setCards: (state, { payload }) => {
      state.cards.data = {
        ...state.cards.data,
        ...payload.data.data,
      };
    },
    setBalanceCards: (state, { payload }) => {
      state.cards.data = {
        ...state.cards.data,
        balance: payload.balance,
        commissions: payload.commissions,
      };
    },

    setFilterChart(state, { payload }) {
      state.chart.filters = {
        ...state.chart.filters,
        ...payload,
      };
    },
    setChart: (state, { payload }) => {
      state.chart.data = payload.data.data;
    },
  },
});

export const {
  setLoading,
  setDataTable,
  setCards,
  setChart,
  setFilterChart,
  setFilterCabinetChart,
  setCabinetChart,
  setPoleData,
  setPoleDialog,
  resetPoleData,
  setCardsSmsBalance,
  setBalanceCards,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;

//axios
const cards = () => async (dispatch) => {
  try {
    dispatch(setLoading("cards"));
    const cardsRes = await statisticsApi.cards();
    const balance = await statisticsApi.getBalance();
    dispatch(setBalanceCards(balance.data.data[0]));
    dispatch(setCards(cardsRes));
    dispatch(setLoading("cards"));
  } catch (err) {
    dispatch(setLoading("cards"));
    throw new Error(err);
  }
};

const balance = () => async (dispatch) => {
  try {
    dispatch(setLoading("cards"));
    const balance = await statisticsApi.getBalance();
    dispatch(setBalanceCards(balance.data.data[0]));
    dispatch(setLoading("cards"));
  } catch (err) {
    dispatch(setLoading("cards"));
    throw new Error(err);
  }
};

const chart = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("chart"));
    const filters = getState().statistics.chart.filters;
    const chartRes = await statisticsApi.chart(filters);
    dispatch(setChart(chartRes));
    dispatch(setLoading("chart"));
  } catch (err) {
    dispatch(setLoading("chart"));
    throw new Error(err);
  }
};

export const Statistics = {
  cards,
  chart,
  balance,
};
