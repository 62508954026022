import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const offersApi = factory.get("offers");

const initialState = {
  active_offers_list: {
    loading: false,
    list: [],
  },
};

const variablesSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setActiveOffersList: (state, { payload }) => {
      state.active_offers_list.list = payload || [];
    },
  },
});

export const { setLoading, setActiveOffersList } = variablesSlice.actions;

export default variablesSlice.reducer;

export const activeOffersList = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("active_offers_list"));
    const res = await offersApi.activeOffersList();
    dispatch(setActiveOffersList(res.data.data));
    dispatch(setLoading("active_offers_list"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setLoading("active_offers_list"));
    throw new Error(err);
  }
};

export const OffersActions = {
  activeOffersList,
};
