import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const call_customer_historyaApi = factory.get("call_customer_history");

const initialState = {
  data: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      customer_name: null,
      user_id: null,
      customer_id: null,
      start_date: null,
      end_date: null,
      mobile: null,
      city_id: null,
      type: null,
    },
    dialog: false,
  },
};

const callCustomerHistorySlice = createSlice({
  name: "callCustomerHistory",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data.data = payload.data;
      state.data.total = payload.total;
      state.data.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.data.filters = {
        ...state.data.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.data.filters = initialState.data.filters;
    },
    setFiltersDialog: (state, { payload }) => {
      state.data.dialog = !state.data.dialog;
    },
  },
});

export const {
  setData,
  setLoading,
  setFilters,
  resetFilters,
  setFiltersDialog,
} = callCustomerHistorySlice.actions;

export default callCustomerHistorySlice.reducer;

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().callCustomerHistory.data.filters;
    const _filters = {
      ...filters,
      user_id: filters.user_id ? filters.user_id.id : null,
      customer_id: filters.customer_id ? filters.customer_id.id : null,
      city_id: filters.city_id ? filters.city_id.id : null,
    };
    dispatch(setLoading("data"));
    const res = await call_customer_historyaApi.index(_filters);
    dispatch(setData(res.data));
  } catch (err) {
    dispatch(setLoading("data"));
    throw new Error(err);
  }
};

export const CallCustomerHistory = {
  index,
};
