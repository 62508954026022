import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  cards() {
    return api.get(`client/statistic`);
  },
  chart(p) {
    const params = paramsFactory(p);
    return api.get(`client/statistic/installation?${params}`);
  },
  cabinet(p) {
    const params = paramsFactory(p);
    return api.get(`statistic/cabinet?${params}`);
  },
  pole(id) {
    return api.get(`statistic/pole?cabinet_id=${id}`);
  },
  statisticsBalance() {
    return api.get(`statistic/get-balance?take=1`);
  },
  ftthSmsBalance() {
    return api.get(`ftth/get-balance`);
  },
  getBalance() {
    return api.get("client/balance");
  },
};
