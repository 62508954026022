import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Announcements } from "../../app/slices/announcementSlice";

const AnnouncementDialog = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [latestAnnouncement, setLatestAnnouncement] = useState(null);

  const announcements = useSelector(
    (state) => state.announcement.last_announcements.data
  );
  useEffect(() => {
    // Dispatch the action to fetch the latest announcement
    dispatch(Announcements.lastAnnouncement());
  }, [dispatch]);

  useEffect(() => {
    if (announcements.length > 0) {
      const latest = announcements[0];
      const readAnnouncements =
        JSON.parse(localStorage.getItem("readAnnouncements")) || [];

      // If the announcement is new (not in readAnnouncements), show the dialog
      if (!readAnnouncements.includes(latest.id)) {
        setLatestAnnouncement(latest);
        setOpen(true);
      }
    }
  }, [announcements]);

  const handleClose = () => {
    if (latestAnnouncement) {
      // Get the current list of read announcements from localStorage
      const readAnnouncements =
        JSON.parse(localStorage.getItem("readAnnouncements")) || [];

      // Add the latest announcement ID to the list
      const updatedReadAnnouncements = [
        ...readAnnouncements,
        latestAnnouncement.id,
      ];

      // Store the updated list in localStorage
      localStorage.setItem(
        "readAnnouncements",
        JSON.stringify(updatedReadAnnouncements)
      );
    }
    setOpen(false);
  };

  return (
    <div>
      {latestAnnouncement && (
        <Dialog open={open} dir="rtl" onClose={handleClose}>
          <DialogTitle
            sx={{
              fontWeight: "bold",
              fontSize: "15px",
              textAlign: "center",
            }}
          >
            {latestAnnouncement.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                lineHeight: 2,
              }}
            >
              {latestAnnouncement.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button onClick={handleClose} color="primary">
              نعم تم الاطلاع
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AnnouncementDialog;
