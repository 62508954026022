import { useState, useEffect } from "react";
import {
  Box,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@mui/material";

//styles and theme
import { useTheme } from "@mui/material/styles";
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
//icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AiOutlineHome } from "react-icons/ai";
import { BiTask } from "react-icons/bi";

import { useLocation, useNavigate } from "react-router-dom";
import { TbReportSearch, TbSocial } from "react-icons/tb";
import { HiOutlineCreditCard } from "react-icons/hi";
import { FiUsers } from "react-icons/fi";
import { GrHostMaintenance, GrTransaction } from "react-icons/gr";
import { RiCustomerService2Fill } from "react-icons/ri";
import { IoMdRefresh } from "react-icons/io";
import { TfiAnnouncement } from "react-icons/tfi";

//store
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../../app/slices/appSlice";
const SideBar = () => {
  //uses
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //constants
  const drawerWidth = 220;

  //states
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const {
    task_notifications: { new_customer, maintenance, subscription_request },
  } = useSelector((state) => state.task);
  // const [mobileOpen, setMobileOpen] = useState(false);

  //selectors and media queries
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const drawerOpen = useSelector((state) => state.app.is_drawer_opened);

  const getNotificationCount = (id) => {
    switch (id) {
      case 11:
        return new_customer;
      case 4:
        return maintenance;
      case 20:
        return subscription_request;
      default:
        return 0;
    }
  };

  const links = [
    {
      id: 1,
      name: "الرئيسية",
      icon: <AiOutlineHome size={18} />,
      path: "/",
      sub: [],
    },
    {
      id: 2,
      name: "المشتركين",
      icon: <FiUsers size={18} />,
      path: "/customers",
      sub: [],
    },
    {
      id: 3,
      name: "الاشتراكات",
      icon: <HiOutlineCreditCard size={20} />,
      path: "/subscriptions",
      sub: [],
    },
    {
      id: 133,
      name: "المعاملات الماليه",
      icon: <GrTransaction size={18} />,
      path: "/transactions",
      sub: [],
    },
    {
      id: 4,
      name: "مهام الصيانة",
      icon: <GrHostMaintenance size={18} />,
      path: "/maintenance-task",
      sub: [],
      is_badge: true,
    },
    {
      id: 11,
      name: "طلبات النصب",
      icon: <BiTask size={18} />,
      path: "/task",
      sub: [],
      is_badge: true,
    },
    {
      id: 20,
      name: "طلبات الاشتراك ",
      icon: <IoMdRefresh size={18} />,
      path: "/subscription-request-task",
      sub: [],
      is_badge: true,
    },
    {
      id: 5,
      name: "التقارير",
      icon: <TbReportSearch size={18} />,
      path: "/reports",
      sub: [],
    },
    {
      id: 46,
      name: "خدمة المشتركين",
      icon: <RiCustomerService2Fill size={18} />,
      path: "/call-customer-history",
      sub: [],
    },
    {
      id: 88,
      name: "اشتراك خدمة",
      icon: <TbSocial size={18} />,
      path: "/subscription-service",
      sub: [],
    },
    {
      id: 32789,
      name: "الأعلانات و الأشعارات",
      icon: <TfiAnnouncement size={18} />,
      path: "/notifications-announcement",
      sub: [],
    },
  ];

  const drawerLinks = (
    <Box m={1}>
      <List
        dense={false}
        sx={{
          "& .MuiListItem-root:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
            fontWeight: "bold",
          },
        }}
      >
        {links.map((item, index) => (
          <div key={index}>
            <ListItem key={index} disablePadding>
              <ListItemButton
                key={index}
                selected={item.path === "/" + pathname.split("/")[1]}
                onClick={() => {
                  if (item.sub.length == 0) navigate(item.path);
                  if (index === selectedID) {
                    setOpen(!open);
                  } else {
                    setOpen(true);
                    setSelectedID(index);
                  }
                }}
              >
                {/* notification badge for new customer install and maintenance */}
                <Badge
                  badgeContent={getNotificationCount(item.id)}
                  invisible={item?.is_badge ? false : true}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "25px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                </Badge>

                {/* <ListItemIcon
                  sx={{
                    minWidth: "25px",
                  }}
                >
                  {item.icon}
                </ListItemIcon> */}
                <ListItemText
                  sx={{
                    fontSize: "0.8rem",
                    // padding: "0px 15px 0px 0px",
                  }}
                  disableTypography={true}
                  primary={item.name}
                />
                {item.sub.length > 0 ? (
                  open ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : (
                  ""
                )}
              </ListItemButton>
            </ListItem>
          </div>
        ))}
      </List>
    </Box>
  );
  const drawerViewAndScroll = (
    <>
      <Box>
        <Box
          sx={{ display: "flex", mx: "auto", justifyContent: "center", mt: 1 }}
        ></Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={
            {
              // height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
              // paddingLeft: "10px",
              // paddingRight: "10px",
            }
          }
        >
          {drawerLinks}
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>{drawerLinks}</Box>
      </MobileView>
    </>
  );

  useEffect(() => {
    if (matchUpMd) {
      dispatch(setDrawer());
    }
  }, [matchUpMd]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: drawerOpen ? drawerWidth : "0px" }}
      aria-label="sidebar"
    >
      <Drawer
        dir="rtl"
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(setDrawer())}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            borderRight: "none",
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawerViewAndScroll}
      </Drawer>
    </Box>
  );
};

export default SideBar;
