import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`client/client?${p}`);
  },
  getById(id) {
    return api.post(`client/client/${id}`);
  },
  changePassword(data) {
    return api.post(`client/change-password/${data.id}`, {
      password: data.password,
    });
  },
  create(data) {
    return api.post(`client/client`, data);
  },
  update(id, data) {
    return api.post(`client/client/${id}`, data);
  },
  deleteAttachment(id) {
    return api.delete(`client/attachment/${id}`);
  },
  changeStatus(id) {
    return api.put(`client/active/${id}`);
  },
  deleteClient(id) {
    return api.delete(`client/client/${id}`);
  },
  // transactions
  getTransactions(params) {
    const p = paramsFactory(params);
    return api.get(`client/transaction/index?${p}`);
  },
  addRemoveBalance(data) {
    return api.post(`client/transaction/commission`, data);
  },
  exportToExcel(params) {
    const p = paramsFactory(params);
    return api.get(`client/transaction/index?${p}`, {
      responseType: "blob",
    });
  },
};
