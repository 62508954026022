import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login(data) {
    return api.post(`client/login`, data);
  },
  loginVerify(data) {
    return api.post(`/api/v1/login-verification`, data);
  },
  logout() {
    return api.post(`client/logout`);
  },
};
