import { forwardRef } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Slide,
  Typography,
  Button,
  DialogActions,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleConfirmDialog,
  setConfirmDialogLoading,
} from "../../app/slices/appSlice";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = ({ title, subtitle, onConfirm }) => {
  const dispatch = useDispatch();

  const { confirm_dialog } = useSelector((state) => state.app);
  // const { c_dialog } = useSelector((state) => state.app);

  return (
    <Dialog
      open={confirm_dialog.open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      // onClose={() => {
      //   dispatch(toggleConfirmDialog());
      // }}
      aria-describedby="confirm-dialog"
    >
      <DialogTitle>{confirm_dialog.title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="initial">
          {confirm_dialog.message}
        </Typography>
        {confirm_dialog.loading && (
          <Typography variant="h3" color="initial">
            انتضر قليلا
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <LoadingButton
            loading={confirm_dialog.loading}
            variant="contained"
            color="primary"
            sx={{ px: 5 }}
            // disabled={confirm_dialog.loading}
            onClick={() => {
              dispatch(setConfirmDialogLoading());
              confirm_dialog.onConfirm();
            }}
          >
            نعم
          </LoadingButton>
          {/* {confirm_dialog.loading && ( */}
          <Button
            variant="outlined"
            disabled={confirm_dialog.loading}
            color="secondary"
            onClick={() => {
              dispatch(toggleConfirmDialog());
            }}
          >
            لا
          </Button>
          {/* )} */}
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
