import api from "../../api/apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export

const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  target(p) {
    const params = paramsFactory(p);
    return api.get(`report/target?${params}`);
  },
  targetSummary(p) {
    const params = paramsFactory(p);
    return api.get(`report/target-summary?${params}`);
  },
  salesCustomers(p) {
    return api.post(`report/sales-customer`, p);
  },
  subscriptions(p) {
    const params = paramsFactory(p);
    return api.get(`client/report/subscription?${params}`);
  },
  cabinets(p) {
    const params = paramsFactory(p);
    return api.get(`report/cabinet?${params}`);
  },
  poles(id) {
    return api.get(`report/pole?cabinet_id=${id}`);
  },
  maintenance(p) {
    return api.post(`client/report/maintenance`, p);
  },
  kpi(p) {
    const params = paramsFactory(p);
    return api.get(`report/kpi?${params}`);
  },
  cabinetsByDate(p) {
    const params = paramsFactory(p);
    return api.get(`report/cabinet-growth?${params}`);
  },
  newCustomersTasks(p) {
    return api.post(`report/new-customers-tasks`, p);
  },
};
