import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`client/cabinet?${p}`);
  },
  create(data) {
    return api.post(`client/cabinet`, data);
  },
  update(data) {
    return api.put(`client/cabinet/${data.id}`, data);
  },
  delete(id) {
    return api.delete(`client/cabinet/${id}`);
  },
  deletePole(id) {
    return api.delete(`client/pole/${id}`);
  },
  createPole(data) {
    return api.post(`client/pole`, data);
  },

  updatePole(data) {
    return api.put(`client/pole/${data.pole_id}`, data);
  },
};
